import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(crab)": [23,[5]],
		"/(app)/(crab)/account": [7,[2,3]],
		"/(app)/(crab)/account/billing": [8,[2,3]],
		"/(app)/(crab)/account/confirm": [9,[2,3]],
		"/(app)/(crab)/account/finalize": [10,[2,3]],
		"/(app)/explore": [14,[2]],
		"/(app)/explore/[trip]": [~15,[2]],
		"/(crab)/inv/[token]": [~27,[5]],
		"/(crab)/legal/privacy-policy": [28,[5]],
		"/(crab)/legal/terms": [29,[5]],
		"/(crab)/(auth)/login": [24,[5,6]],
		"/(crab)/magic-link/[token]": [30,[5]],
		"/(app)/recommendations": [16,[2]],
		"/(app)/recommendations/[recommendation]": [~17,[2]],
		"/(crab)/(auth)/register-now": [26,[5,6]],
		"/(crab)/(auth)/register": [25,[5,6]],
		"/(crab)/reset": [31,[5]],
		"/(crab)/reset/[token]": [~32,[5]],
		"/(app)/saved-places": [18,[2]],
		"/(app)/saved-places/[saved_place]": [19,[2]],
		"/(app)/trips": [20,[2]],
		"/(app)/trips/[trip]": [~21,[2]],
		"/(app)/trips/[trip]/map": [~22,[2]],
		"/(app)/(crab)/upgrade": [11,[2,3,4]],
		"/(app)/(crab)/upgrade/checkout": [12,[2,3,4]],
		"/(app)/(crab)/upgrade/confirmation": [~13,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';